import React from "react";
import styled from "styled-components";

import { SignupSubHeader } from "../signup/SignupView";

import { Header } from "./SharedStyles";

interface MagicLinkSentViewProps {
  email: string;
}

const MagicLinkSubHeader = styled(SignupSubHeader)`
  margin-bottom: 29px;

  font-size: ${({ theme }) => theme.fontSize.LARGE};

  strong {
    font-weight: 600;
  }

  p {
    margin-top: 12px;

    font-size: ${({ theme }) => theme.fontSize.SMALL};

    opacity: 0.75;
  }
`;

export const MagicLinkSentView: React.ComponentType<MagicLinkSentViewProps> =
  React.memo(function MagicLinkSentView({ email }) {
    return (
      <>
        <Header>
          📪
          <br />
          Check your inbox!
        </Header>
        <MagicLinkSubHeader>
          Click the confirmation link sent to <strong>{email}</strong>.
        </MagicLinkSubHeader>
      </>
    );
  });
