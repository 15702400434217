import { Classes, Intent } from "@blueprintjs/core";
import React, { useCallback } from "react";
import styled from "styled-components";

import { HexButton } from "../../hex-components";
import { CyData } from "../../util/cypress";
import { UploadIcon } from "../icons/CustomIcons";

const FileUploadContainer = styled.div`
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme.fontColor.MUTED};
`;

const Header = styled.div`
  margin-bottom: 8px;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  font-size: ${({ theme }) => theme.fontSize.EXTRA_SMALL};
  text-transform: uppercase;
`;

const ChosenFile = styled.div<{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: ${({ $small }) => ($small ? "80px" : "250px")};
  margin-bottom: ${({ $small }) => ($small ? "5px" : "10px")};
  padding: ${({ $small }) => ($small ? "5px" : "10px 16px 12px")};

  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  font-size: ${({ $small, theme }) =>
    $small ? theme.fontSize.SMALL : theme.fontSize.DEFAULT};

  background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow.CARD};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;

  .${Classes.BUTTON}:first-child {
    margin-right: 5px;
  }
`;

export interface FileItemProps {
  className?: string;
  file: File;
  onUpload: (file: File) => void;
  onClearFile: () => void;
  uploading: boolean;
  disabled?: boolean;
  $small?: boolean;
}

export const FileUpload: React.FunctionComponent<FileItemProps> = (props) => {
  const {
    $small = false,
    className,
    disabled,
    file,
    onClearFile,
    onUpload,
    uploading,
  } = props;

  const onCancel = useCallback(() => {
    onClearFile();
  }, [onClearFile]);

  const onUploadCallback = useCallback(() => {
    onUpload(file);
  }, [file, onUpload]);

  return (
    <FileUploadContainer className={className}>
      <ChosenFile $small={$small}>
        {!$small && <Header>Chosen file</Header>}
        {file.name}
      </ChosenFile>
      <Actions>
        <HexButton extraSmall={$small} text="Cancel" onClick={onCancel} />
        <HexButton
          data-cy={CyData.UPLOAD_FILE}
          disabled={disabled}
          extraSmall={$small}
          icon={<UploadIcon />}
          intent={Intent.PRIMARY}
          loading={uploading}
          text="Upload file"
          onClick={onUploadCallback}
        />
      </Actions>
    </FileUploadContainer>
  );
};
