import styled from "styled-components";

import { LoginHeader } from "../login/LoginPage";

export const Header = styled(LoginHeader)`
  margin: 0 0 8px 0;

  line-height: 44px;
`;

export const SubHeader = styled.h2`
  margin: 0;

  color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  font-size: 24px;
  line-height: 24px;
  text-align: center;
`;
