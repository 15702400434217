import { ProjectLanguage, SpecialVersionType } from "@hex/common";
import { useCallback } from "react";
import { useHistory } from "react-router";

import { Routes } from "../../route/routes";
import { useFeatureGates } from "../feature-gate/FeatureGateContext";
import { DEFAULT_COMPONENT_TITLE } from "../logic/ProjectMetadata";

import { useCreateComponentMutation } from "./NewComponentDialog.generated";

export interface UseCreateNewDefaultComponentResult {
  createPythonComponent: () => Promise<void>;
  createRComponent: () => Promise<void>;
}

export function useCreateNewDefaultComponent(): UseCreateNewDefaultComponentResult {
  const { components: componentsEnabled } = useFeatureGates();

  const history = useHistory();
  const [createComponent] = useCreateComponentMutation();

  const createComponentCallback = useCallback(
    async (projectLanguage: ProjectLanguage) => {
      if (!componentsEnabled) {
        throw new Error("Components are not enabled");
      }
      try {
        const { data } = await createComponent({
          variables: {
            name: DEFAULT_COMPONENT_TITLE,
            projectLanguage,
            description: null,
            kernelImage: null,
            kernelSize: null,
            cellIdsToCopy: null,
            autoPublish: false,
          },
        });
        if (data?.createHex?.id == null) {
          throw new Error("Creation failed");
        }
        Routes.push(history, Routes.COMPONENT, {
          hexId: data.createHex.id,
          version: SpecialVersionType.DRAFT,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [componentsEnabled, createComponent, history],
  );

  const createPythonComponent = useCallback(async () => {
    await createComponentCallback(ProjectLanguage.PYTHON);
  }, [createComponentCallback]);
  const createRComponent = useCallback(async () => {
    await createComponentCallback(ProjectLanguage.R);
  }, [createComponentCallback]);

  return { createPythonComponent, createRComponent };
}
