import { gql } from "@apollo/client";
import { PromoCode, SignupErrorReason } from "@hex/common";
import React, { useMemo } from "react";

import {
  AlertBody,
  AlertHeader,
  ErrorContainer,
  Link,
} from "../../route/LoginRoute";
import { QueryParams } from "../../route/routes";
import { LoadingView } from "../../views/LoadingView";

import { usePromoSignupViewQuery } from "./PromoSignupView.generated";
import { Header } from "./SharedStyles";
import { SignupAuthnContainer } from "./SignupAuthnContainer";
import { SignupSubHeader } from "./SignupView";

gql`
  fragment PromoFragment on Promo {
    id
    code
    trialDays
  }
`;

gql`
  query PromoSignupView($promoCode: PromoCode!) {
    getPromo(promoCode: $promoCode) {
      id
      ...PromoFragment
    }
  }
`;

interface PromoSignupViewProps {
  failed?: SignupErrorReason | null;
  authQueryParams?: QueryParams;
  promoCode: PromoCode;
}

export const PromoSignupView: React.ComponentType<PromoSignupViewProps> =
  React.memo(function SignupView({
    authQueryParams = {},
    failed = null,
    promoCode,
  }) {
    const { data, error, loading } = usePromoSignupViewQuery({
      variables: { promoCode },
    });

    let validPromo: boolean;
    // If promo isn't valid we should allow them signup
    if (error || (!loading && !data?.getPromo)) {
      validPromo = false;
    } else {
      validPromo = true;
      // only attach promo code if its valid, this way if not valid we'll just
      // hit normal signup flow
      // We let users know they'll be going through the normal signup flow with
      // an alert body below
      authQueryParams["promoCode"] = promoCode;
    }

    const { trialDays } = data?.getPromo || { trialDays: 14 };

    const title = useMemo(() => {
      return !validPromo ? (
        "Get started with Hex"
      ) : (
        <>Redeem your {trialDays}-day Hex trial</>
      );
    }, [trialDays, validPromo]);

    const header = useMemo(() => {
      return !validPromo ? (
        <ErrorContainer
          css={`
            margin-bottom: 0px;
          `}
        >
          <AlertHeader>Oops! That promo is expired or invalid.</AlertHeader>
          <AlertBody>
            You can still sign up for a 14-day Team trial! After that, select a
            plan or keep using Hex Community for free.
          </AlertBody>
        </ErrorContainer>
      ) : (
        <SignupSubHeader>
          <>
            Sign up to start with a {trialDays}-day trial of the{" "}
            <Link
              href="https://hex.tech/pricing"
              rel="noopener noreferrer"
              target="_blank"
            >
              Team plan
            </Link>
            .<br /> Please use your work email.
          </>
        </SignupSubHeader>
      );
    }, [trialDays, validPromo]);

    if (loading) {
      return <LoadingView />;
    }

    // TODO(VELO-4743): Revisit copy for various invite link failures.
    return (
      <>
        <Header>{title}</Header>
        <SignupAuthnContainer
          authQueryParams={authQueryParams}
          defaultSubHeader={header}
          failed={failed}
        />
      </>
    );
  });
