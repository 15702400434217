import { gql } from "@apollo/client";
import { Classes } from "@blueprintjs/core";
import {
  KernelImage,
  KernelSize,
  ProjectLanguage,
  SpecialVersionType,
} from "@hex/common";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { HexButton, HexCallout, HexDialog } from "../../hex-components";
import { HexClasses } from "../../hex-components/classes.js";
import { useGlobalHotKey } from "../../hooks/useGlobalHotKey";
import { useToggleState } from "../../hooks/useToggleState";
import { Routes } from "../../route/routes";
import { CyData } from "../../util/cypress";
import { useDialog } from "../../util/dialogs";
import { HotKeys } from "../../util/hotkeys";
import { Keys, evtModKey } from "../../util/Keys";
import { useHexFlag } from "../../util/useHexFlags";
import {
  DESCRIPTION_MAX_LENGTH,
  DescriptionLengthLimit,
  DescriptionTextArea,
  DescriptionWrapper,
} from "../app/ProjectDescription";
import { ControlledContentEditable } from "../common/ControlledContentEditable";
import { KernelImagePicker } from "../compute/KernelImagePicker";
import { KernelSizePicker } from "../compute/KernelSizePicker";
import { ProjectLanguagePicker } from "../compute/ProjectLanguagePicker";
import { useFeatureGates } from "../feature-gate/FeatureGateContext";
import { SingleChevronUpIcon, WarningIcon } from "../icons/CustomIcons";
import { EditableNameWrapper } from "../logic/EditableNameWrapper";
import {
  DEFAULT_COMPONENT_TITLE,
  TitleContentEditable,
} from "../logic/ProjectMetadata";

import {
  CreateComponentMutation,
  useCreateComponentMutation,
} from "./NewComponentDialog.generated";
import { useCreateNewDefaultComponent } from "./useCreateNewDefaultComponent";

gql`
  mutation CreateComponent(
    $name: String!
    $projectLanguage: ProjectLanguage
    $description: String
    $kernelSize: KernelSize
    $kernelImage: KernelImage
    $cellIdsToCopy: [CellId!]
    $autoPublish: Boolean!
  ) {
    createHex(
      name: $name
      projectLanguage: $projectLanguage
      description: $description
      kernelSize: $kernelSize
      kernelImage: $kernelImage
      cellIdsToCopy: $cellIdsToCopy
      autoPublish: $autoPublish
      hexType: COMPONENT
    ) {
      id
      lastPublishedVersion {
        id
      }
    }
  }
`;

const StyledDialog = styled(HexDialog)`
  && {
    width: 520px;

    padding: 0;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;
`;

const Metadata = styled.div`
  display: flex;
  padding: 20px 20px 15px;
`;

const Footer = styled.div`
  display: flex;
  padding: 0 10px 0 20px;

  border-top: 1px solid ${({ theme }) => theme.borderColor.MUTED};
`;

const AdvancedFooterGroup = styled.div`
  height: 100%;
  margin-left: -4px;
  overflow: hidden;
`;

const AdvancedToggleButton = styled(HexButton)`
  .${Classes.BUTTON_TEXT}:not(:last-child) {
    margin-right: 3px;
  }
`;

const AdvancedInner = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  height: calc(100% * 2);

  transition: transform calc(${({ theme }) => theme.animation.duration} * 2)
    ${({ theme }) => theme.animation.easing};

  ${({ $isOpen }) =>
    $isOpen ? "transform: translate(0, -50%);" : "transform: translate(0, 0);"}
`;

const AdvancedSection = styled.div<{ $faded: boolean }>`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  height: 50%;

  transition: opacity calc(${({ theme }) => theme.animation.duration} * 2)
    ${({ theme }) => theme.animation.easing};

  ${({ $faded }) => ($faded ? "opacity: 0;" : "opacity: 1;")}
`;

const LeftActions = styled.div`
  display: flex;
  flex: initial;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
`;

const RightActions = styled.div`
  display: flex;
  flex: none;
  gap: 10px;
  align-items: center;
  margin-left: auto;
  padding: 10px 0;

  .${HexClasses.HEX_BUTTON} {
    flex: none;
  }
`;

export const CreatingFromWrapper = styled.div`
  margin-top: 5px;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

export const NewComponentDialog: React.ComponentType = React.memo(
  function NewComponentDialog() {
    const history = useHistory();
    const {
      closeDialog: closeDialog_,
      isOpen,
      openDialog,
    } = useDialog("new-component");
    const additionalBaseImages = useHexFlag("additional-base-images");
    const { components: componentsEnabled, configurableKernelSize } =
      useFeatureGates();
    const [createComponent] = useCreateComponentMutation();

    const [isCreating, setIsCreating] = useState<boolean>(false);

    const [currentTitle, setCurrentTitle] = useState<string>(
      DEFAULT_COMPONENT_TITLE,
    );
    const [editingTitle, setIsEditingTitle] = useState<boolean>(false);
    const [creationError, setCreationError] = useState<string>();
    const [currentDescription, setCurrentDescription] = useState<string>("");

    const nameRef = useRef<ControlledContentEditable>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);

    const [appliedLanguage, setAppliedLanguage] = useState<ProjectLanguage>(
      ProjectLanguage.PYTHON,
    );
    const [
      advancedOpen,
      ,
      { setFalse: closeAdvanced, toggle: toggleAdvanced },
    ] = useToggleState(false);
    const [kernelSize, setKernelSize] = useState<KernelSize | null>(null);
    const [kernelImage, setKernelImage] = useState<KernelImage | null>(null);

    const clearState = useCallback(() => {
      setIsCreating(false);
      setCreationError(undefined);
      closeAdvanced();
    }, [closeAdvanced]);
    const closeDialog = useCallback(() => {
      clearState();
      closeDialog_();
    }, [clearState, closeDialog_]);

    const toggleDialog = useCallback(() => {
      if (isOpen) {
        closeDialog();
      } else {
        openDialog();
        setIsEditingTitle(true);
      }
    }, [closeDialog, isOpen, openDialog]);

    // Select name on mount
    useEffect(() => {
      setIsEditingTitle(true);
    }, [isOpen]);

    // This allows the textarea to grow with the content
    useEffect(() => {
      if (descriptionRef.current != null) {
        descriptionRef.current.style.height = "0px";
        const scrollHeight = descriptionRef.current.scrollHeight;
        descriptionRef.current.style.height = scrollHeight + "px";
      }
    }, [currentDescription]);

    const descriptionTextAreaCallback = useCallback((evt) => {
      setCurrentDescription(evt.target.value);
    }, []);

    const saveTitle = useCallback((): void => {
      setCurrentTitle(currentTitle || DEFAULT_COMPONENT_TITLE);
      setIsEditingTitle(false);
    }, [currentTitle]);

    const cancelName = useCallback((): void => {
      saveTitle();
      closeDialog();
    }, [closeDialog, saveTitle]);

    const selectLanguage = useCallback((language: ProjectLanguage): void => {
      setAppliedLanguage(language);
    }, []);

    const createComponentCallback = useCallback(
      async (autoPublish: boolean): Promise<CreateComponentMutation> => {
        const { data } = await createComponent({
          variables: {
            name: currentTitle,
            projectLanguage: appliedLanguage,
            description: currentDescription,
            kernelImage,
            kernelSize,
            cellIdsToCopy: null,
            autoPublish,
          },
        });
        if (data?.createHex == null) {
          throw new Error("Creation failed");
        }
        return data;
      },
      [
        createComponent,
        currentTitle,
        appliedLanguage,
        currentDescription,
        kernelImage,
        kernelSize,
      ],
    );

    const createComponentAndNavigate = useCallback(async () => {
      setIsCreating(true);
      try {
        const data = await createComponentCallback(false);
        if (data != null) {
          clearState();
          Routes.push(history, Routes.COMPONENT, {
            hexId: data.createHex.id,
            version: SpecialVersionType.DRAFT,
          });
        }
      } catch (e) {
        console.error(e);
        setCreationError("Creation failed");
        setIsCreating(false);
      }
    }, [clearState, createComponentCallback, history]);

    const { createPythonComponent } = useCreateNewDefaultComponent();

    // New component hot key
    useGlobalHotKey(
      async (evt) => {
        if (!isOpen && componentsEnabled) {
          evt.preventDefault();
          await createPythonComponent();
          return true;
        }
        return false;
      },
      [componentsEnabled, createPythonComponent, isOpen],
      { keys: HotKeys.NEW_COMPONENT, name: "New component" },
    );

    const modEnterKeyPress = useCallback(
      (evt) => {
        // shift-enter
        if (evt.key === Keys.ENTER && evtModKey(evt)) {
          evt.preventDefault();
          void createComponentAndNavigate();
        }
      },
      [createComponentAndNavigate],
    );

    const justEnterKeyPress = useCallback(
      (evt) => {
        // enter
        if (evt.key === Keys.ENTER) {
          evt.preventDefault();
          void createComponentAndNavigate();
        }
      },
      [createComponentAndNavigate],
    );

    return (
      <StyledDialog
        isCloseButtonShown={true}
        isOpen={isOpen}
        title="New component"
        usePortal={true}
        onClose={toggleDialog}
      >
        <Body>
          <EditableNameWrapper>
            <TitleContentEditable
              ref={nameRef}
              $editable={true}
              content={currentTitle}
              isEditing={editingTitle}
              selectAllWhenEditingStarts={true}
              onBlur={saveTitle}
              onCancel={cancelName}
              onChange={setCurrentTitle}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => setIsEditingTitle(true)}
              onKeyPressDeprecated={justEnterKeyPress}
              onSave={saveTitle}
            />
          </EditableNameWrapper>
          <DescriptionWrapper>
            <DescriptionTextArea
              ref={descriptionRef}
              $editable={true}
              maxLength={DESCRIPTION_MAX_LENGTH}
              placeholder="Add description..."
              rows={1}
              value={currentDescription}
              onChange={descriptionTextAreaCallback}
              onKeyDown={modEnterKeyPress}
            />
            <DescriptionLengthLimit
              count={currentDescription.length}
              max={DESCRIPTION_MAX_LENGTH}
            />
          </DescriptionWrapper>
        </Body>
        <Metadata>
          <ProjectLanguagePicker
            appliedLanguage={appliedLanguage}
            onSelectLanguage={selectLanguage}
          />
        </Metadata>
        {creationError != null && (
          <HexCallout
            $size="small"
            css={`
              margin: 0px 20px 20px 20px;
              width: 480px;
            `}
            icon={<WarningIcon />}
            intent="danger"
          >{`${creationError}. Try again, or contact support if  the issue persists.`}</HexCallout>
        )}
        <Footer>
          <LeftActions>
            {(additionalBaseImages || configurableKernelSize) && (
              <AdvancedFooterGroup>
                <AdvancedInner $isOpen={advancedOpen}>
                  <AdvancedSection $faded={advancedOpen}>
                    <AdvancedToggleButton
                      minimal={true}
                      rightIcon={<SingleChevronUpIcon iconSize={14} />}
                      small={true}
                      text="Advanced options"
                      onClick={toggleAdvanced}
                    />
                  </AdvancedSection>
                  <AdvancedSection $faded={!advancedOpen}>
                    {additionalBaseImages && (
                      <KernelImagePicker
                        currentKernelImage={kernelImage}
                        projectLanguage={appliedLanguage}
                        onSelect={setKernelImage}
                      />
                    )}
                    {configurableKernelSize && (
                      <KernelSizePicker
                        currentKernelSize={kernelSize}
                        onSelect={setKernelSize}
                      />
                    )}
                  </AdvancedSection>
                </AdvancedInner>
              </AdvancedFooterGroup>
            )}
          </LeftActions>
          <RightActions>
            <HexButton
              data-cy={CyData.CREATE_COMPONENT}
              intent="success"
              loading={isCreating}
              text="Create component"
              onClick={createComponentAndNavigate}
            />
          </RightActions>
        </Footer>
      </StyledDialog>
    );
  },
);
