import React, { ReactNode } from "react";
import styled from "styled-components";

import { HexTooltip } from "../../hex-components";
import { GroupsIcon } from "../icons/CustomIcons";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  flex: none;
`;

const TooltipContentDiv = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

const TooltipNameDiv = styled.div`
  color: ${({ theme }) => theme.fontColor.DEFAULT};
`;

const TooltipMetaDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;

export interface GroupAvatarProps {
  name: string;
  userCount?: number;
  icon?: ReactNode;
}

export const GroupAvatar: React.ComponentType<GroupAvatarProps> = React.memo(
  function GroupAvatar({ icon, name, userCount }: GroupAvatarProps) {
    const tooltipContent = (
      <TooltipContentDiv>
        <TooltipNameDiv>{name}</TooltipNameDiv>
        <TooltipMetaDiv>
          {userCount != null &&
            `${userCount} user${userCount !== 1 ? "s" : ""}`}
        </TooltipMetaDiv>
      </TooltipContentDiv>
    );

    return (
      <HexTooltip content={tooltipContent}>
        <Wrapper>{icon || <GroupsIcon />}</Wrapper>
      </HexTooltip>
    );
  },
);
