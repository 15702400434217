import { SignupErrorReason } from "@hex/common";
import React, { useMemo } from "react";
import styled from "styled-components";

import { Link } from "../../route/LoginRoute";
import { QueryParams } from "../../route/routes";

import { Header, SubHeader } from "./SharedStyles";
import { SignupAuthnContainer } from "./SignupAuthnContainer";

export const SignupSubHeader = styled(SubHeader)`
  font-size: ${({ theme }) => theme.fontSize.DEFAULT};
  line-height: 22px;
`;

interface SignupViewProps {
  failed?: SignupErrorReason | null;
  invited?: boolean;
  selectedPlanFromPricing?: string | null;
  authQueryParams?: QueryParams;
}

export const SignupView: React.ComponentType<SignupViewProps> = React.memo(
  function SignupView({
    authQueryParams = {},
    failed = null,
    invited = false,
    selectedPlanFromPricing,
  }) {
    const header = useMemo(() => {
      return (
        <SignupSubHeader>
          {invited ? (
            "Hex is a collaborative data workspace for teams."
          ) : selectedPlanFromPricing === "team" ? (
            <>
              Sign up for a free 14-day trial of Hex{" "}
              <Link
                href="https://hex.tech/pricing"
                rel="noopener noreferrer"
                target="_blank"
              >
                Team
              </Link>
              , no card required. Please use your work email.
            </>
          ) : selectedPlanFromPricing === "professional" ? (
            <>
              Sign up for a free 14-day trial of Hex{" "}
              <Link
                href="https://hex.tech/pricing"
                rel="noopener noreferrer"
                target="_blank"
              >
                Team
              </Link>
              , no card required. Please use your work email.
            </>
          ) : (
            <>Please use your work email.</>
          )}
        </SignupSubHeader>
      );
    }, [invited, selectedPlanFromPricing]);

    // TODO(VELO-4743): Revisit copy for various invite link failures.
    return (
      <>
        <Header>
          {invited
            ? "You're invited to join your team on Hex"
            : "Create your account"}
        </Header>
        <SignupAuthnContainer
          authQueryParams={authQueryParams}
          defaultSubHeader={header}
          failed={failed}
          invited={invited}
        />
      </>
    );
  },
);
