import {
  HexId,
  MAX_PROJECT_IMPORT_FILE_SIZE,
  SpecialVersionType,
  checkForWarnings,
} from "@hex/common";
import filesize from "filesize";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { HexDialog } from "../../hex-components";
import { useGlobalHotKey } from "../../hooks/useGlobalHotKey";
import {
  useImportIpynbMutation,
  useImportNewProjectVersionMutation,
  useImportProjectMutation,
} from "../../mutations/import.generated";
import { useDispatch, useSelector } from "../../redux/hooks";
import {
  clearImportWarnings,
  selectImportWarningsState,
  setImportWarnings,
} from "../../redux/slices/logicViewSlice";
import { Routes } from "../../route/routes";
import { useDialog } from "../../util/dialogs";
import { HotKeys } from "../../util/hotkeys";

import { ImportProjectWarnings } from "./ImportProjectWarnings";
import { Uploader } from "./Uploader";

const StyledDialog = styled(HexDialog)`
  && {
    width: 600px;

    padding: 0;
  }
`;

interface BaseImportDialogProps {
  dialog: "project-import" | "version-import";
  hexId?: HexId;
}

const ImportDialog: React.FunctionComponent<BaseImportDialogProps> = ({
  dialog,
  hexId,
}) => {
  const history = useHistory();
  const { closeDialog, isOpen } = useDialog(dialog);
  const dispatch = useDispatch();

  const [uploadError, setUploadError] = useState(false);

  const closeUploadDialogCallback = useCallback(
    (args?: { navigate: boolean }) => {
      closeDialog(args);
      dispatch(clearImportWarnings());
      setUploadError(false);
    },
    [closeDialog, dispatch],
  );

  const closeUploadDialogNoArgs = useCallback(() => {
    closeUploadDialogCallback();
  }, [closeUploadDialogCallback]);

  const [
    importVersion,
    { error: importVersionError, loading: importingVersion },
  ] = useImportNewProjectVersionMutation();
  const [importIpynb, { error: importIpynbError, loading: importingIpynb }] =
    useImportIpynbMutation();
  const [
    importProject,
    { error: importProjectError, loading: importingProject },
  ] = useImportProjectMutation();

  const importing = importingVersion || importingIpynb || importingProject;
  const importError =
    importVersionError || importIpynbError || importProjectError;

  const importCommand = useCallback(
    (reader: FileReader, fileName: string) => {
      if (
        fileName.toLowerCase().endsWith(".yaml") ||
        fileName.toLowerCase().endsWith(".yml")
      ) {
        if (hexId && dialog === "version-import") {
          return importVersion({
            variables: {
              contents: reader.result as string,
              hexId: hexId,
            },
          }).then(({ data }) => {
            const hasWarning = checkForWarnings(
              data?.importNewVersion.warnings,
            );
            if (data) {
              if (hasWarning) {
                dispatch(setImportWarnings(data.importNewVersion.warnings));
              } else {
                closeUploadDialogCallback({ navigate: false });
              }
              Routes.push(history, Routes.LOGIC, {
                hexId,
                version: data.importNewVersion.hexVersion.version,
                urlParams: hasWarning ? { dialog } : undefined,
              });
            }
          });
        } else {
          return importProject({
            variables: {
              contents: reader.result as string,
            },
          }).then(({ data }) => {
            if (data) {
              const hasWarning = checkForWarnings(
                data?.importNewProject.warnings,
              );
              if (hasWarning) {
                dispatch(setImportWarnings(data.importNewProject.warnings));
              } else {
                closeUploadDialogCallback({ navigate: false });
              }
              Routes.push(history, Routes.LOGIC, {
                hexId: data.importNewProject.hex.id,
                version: SpecialVersionType.DRAFT,
                urlParams: hasWarning ? { dialog } : undefined,
              });
            }
          });
        }
      } else {
        return importIpynb({
          variables: {
            name: fileName,
            contents: reader.result as string,
          },
        }).then(({ data }) => {
          // we're about to navigate away anyway and the goBack interferes
          // with the navigation
          closeUploadDialogCallback({ navigate: false });
          if (data?.importIpynb?.id) {
            Routes.push(history, Routes.LOGIC, {
              hexId: data.importIpynb.id,
              version: SpecialVersionType.DRAFT,
            });
          }
        });
      }
    },
    [
      closeUploadDialogCallback,
      dialog,
      dispatch,
      hexId,
      history,
      importIpynb,
      importProject,
      importVersion,
    ],
  );

  const onUpload = useCallback(
    (file) => {
      const reader = new FileReader();
      setUploadError(false);

      reader.onabort = (e) => {
        setUploadError(true);
        console.warn("file reading was aborted", e);
      };
      reader.onerror = (e) => {
        setUploadError(true);
        console.error("file reading has failed", e);
      };
      reader.onload = () => {
        importCommand(reader, file.name).catch(() => {
          setUploadError(true);
        });
      };
      reader.readAsText(file);
    },
    [importCommand],
  );

  const warnings = useSelector(selectImportWarningsState);
  const dialogTitle = warnings
    ? "Project imported with warnings"
    : dialog === "project-import"
      ? "Upload Hex project (.yaml) or Jupyter (.ipynb) file as new project"
      : "Upload Hex project (.yaml) file as new version";

  return (
    <StyledDialog
      isCloseButtonShown={true}
      isOpen={isOpen}
      title={dialogTitle}
      usePortal={true}
      onClose={closeUploadDialogNoArgs}
    >
      {warnings ? (
        <ImportProjectWarnings
          closeDialog={closeUploadDialogNoArgs}
          dialog={dialog}
          warnings={warnings}
        />
      ) : (
        <Uploader
          accept={{
            human: `Only Hex .yaml${
              dialog === "project-import" ? " and Jupyter .ipynb" : ""
            } files are supported`,
            regex:
              dialog === "project-import"
                ? /^.*\.(yaml|yml|ipynb)$/i
                : /^.*\.(yaml|yml)$/i,
          }}
          clearFileSideEffect={() => setUploadError(false)}
          error={uploadError}
          errorMessage={importError?.message}
          instructionText={`Drag and drop .yaml${
            dialog === "project-import" ? " or .ipynb" : ""
          }...`}
          maxFileSize={{
            human: filesize(MAX_PROJECT_IMPORT_FILE_SIZE, { round: 0 }),
            value: MAX_PROJECT_IMPORT_FILE_SIZE,
          }}
          uploading={importing}
          onUpload={onUpload}
        />
      )}
    </StyledDialog>
  );
};

export const ImportNewProjectDialog: React.ComponentType = React.memo(
  function ImportNewProjectDialog() {
    // TODO(file-format): Can uncomment this once we delete the standalone ipynb import dialog,
    // const { isOpen, openDialog } = useDialog("project-import");
    //   useGlobalHotKey(
    //     (evt) => {
    //       if (!isOpen) {
    //         evt.preventDefault();
    //         openDialog();
    //       }
    //     },
    //     [isOpen, openDialog],
    //     { keys: HotKeys.IMPORT_PROJECT },
    //   );
    return <ImportDialog dialog="project-import" />;
  },
);

interface ImportVersionDialogProps {
  hexId: HexId;
}
export const ImportNewVersionDialog: React.ComponentType<ImportVersionDialogProps> =
  React.memo(function ImportNewVersionDialog({ hexId }) {
    const { isOpen, openDialog } = useDialog("version-import");

    useGlobalHotKey(
      (evt) => {
        if (!isOpen) {
          evt.preventDefault();
          openDialog();
          return true;
        }
        return false;
      },
      [isOpen, openDialog],
      { keys: HotKeys.IMPORT_VERSION, name: "Import new version" },
    );
    return <ImportDialog dialog="version-import" hexId={hexId} />;
  });
