import { rgba } from "polished";
import React from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import styled from "styled-components";

import { HexButton } from "../../hex-components";
import { CyData } from "../../util/cypress";
import { UploadIcon } from "../icons/CustomIcons";

const Instructions = styled.div<{ $fade?: boolean }>`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  transition: opacity 0.2s ease;

  ${({ $fade }) => ($fade ? `opacity: 0.45;` : "")}
`;

const Box = styled.div<{ hasChosenFile: boolean; isDragActive: boolean }>`
  position: absolute;

  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => rgba(theme.backgroundColor.DEFAULT, 0.5)};
  border: 1px dashed ${({ theme }) => theme.borderColor.DEFAULT};
  border-radius: ${({ theme }) => theme.borderRadius};

  transition: background-color 0.2s ease;

  :focus {
    outline: none;
  }

  &:hover {
    background-color: ${({ theme }) =>
      rgba(theme.backgroundColor.DEFAULT, 0.8)};
  }

  &:active {
    background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
  }

  ${({ isDragActive, theme }) =>
    isDragActive &&
    `
     &&,
     &:hover,
     &:active {
      background-color: ${theme.hoverColor};
      border: 1px solid ${theme.borderColor.DEFAULT};
    }

     ${Instructions} {
      opacity: 0.5;
     }
  `}

  ${({ hasChosenFile, theme }) =>
    hasChosenFile &&
    `
     &&,
     &:hover,
     &:active {
      background-color: ${rgba(theme.intent.PRIMARY, 0.1)};
      border: 1px solid ${rgba(theme.intent.PRIMARY, 0.3)};
      pointer-events: none;
    }

     ${Instructions} {
      opacity: 0;
     }
  `}
`;

const InstructionsText = styled.div<{ $small?: boolean }>`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ $small, theme }) =>
    $small ? theme.fontSize.SMALL : theme.fontSize.DEFAULT};

  pointer-events: none;
`;

const ORDivider = styled.div<{ $small?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 50%;
  margin: ${({ $small }) => ($small ? "5px" : "15px")} 0;

  pointer-events: none;

  &::before,
  &::after {
    display: block;
    flex: 1 1 auto;
    height: 1px;

    background-color: ${({ theme }) => theme.borderColor.DEFAULT};

    content: "";
  }

  span {
    margin: 0 6px;

    color: ${({ theme }) => theme.fontColor.MUTED};
    font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
    font-size: ${({ theme }) => theme.fontSize.EXTRA_SMALL};
    text-transform: uppercase;
  }
`;

const FileSizeHint = styled.div`
  position: absolute;
  bottom: 12px;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

const SmallInstructionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

interface DropzoneProps {
  onDrop: DropzoneOptions["onDrop"];
  disabled: DropzoneOptions["disabled"];
  hasChosenFile: boolean;
  instructionText?: string;
  maxFileSize?: string;
  $small?: boolean;
}

export const Dropzone: React.FunctionComponent<DropzoneProps> = (props) => {
  const {
    $small = false,
    disabled,
    hasChosenFile,
    instructionText,
    maxFileSize,
    onDrop,
  } = props;
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop,
    disabled,
  });

  return (
    <Box
      data-cy={CyData.DROPZONE_BOX}
      {...getRootProps()}
      hasChosenFile={hasChosenFile}
      isDragActive={isDragActive}
    >
      <input
        {...getInputProps()}
        data-cy={CyData.DROPZONE_FILE_INPUT}
        disabled={disabled}
      />
      <Instructions $fade={disabled}>
        {$small ? (
          <SmallInstructionsContainer>
            <InstructionsText $small={true}>
              {instructionText ?? "Drag and drop a file"}
              <span> or </span>
            </InstructionsText>
            <HexButton disabled={disabled} extraSmall={$small} text="Upload" />
          </SmallInstructionsContainer>
        ) : (
          <>
            <InstructionsText $small={false}>
              {instructionText ?? "Drag and drop a file..."}
            </InstructionsText>
            <ORDivider $small={$small}>
              <span>Or</span>
            </ORDivider>
            <HexButton
              data-cy={CyData.DROPZONE_UPLOAD_BUTTON}
              disabled={disabled}
              extraSmall={$small}
              icon={<UploadIcon />}
              text="Upload"
            />
          </>
        )}
        {maxFileSize && (
          <FileSizeHint>Maximum file size is {maxFileSize}.</FileSizeHint>
        )}
      </Instructions>
    </Box>
  );
};
