import React, { ReactNode, useCallback } from "react";

import { useAttribution } from "../../hooks/useAttribution";
import { Routes } from "../../route/routes";
import {
  maybeIncludeTelemetry,
  useIsTelemetryEnabled,
} from "../../util/telemetry";
import { useHexFlag } from "../../util/useHexFlags";
import { GoogleIcon } from "../login/GoogleLogin";
import { LoginOption } from "../login/LoginOption";

interface GoogleSignupProps {
  authQueryParams: Record<string, string>;
  children?: ReactNode;
  disabled?: boolean;
  onSubmit: () => void;
}

export const GoogleSignup: React.ComponentType<GoogleSignupProps> = React.memo(
  function GoogleSignup({
    authQueryParams,
    children,
    disabled = false,
    onSubmit,
  }) {
    const telemetryTimeoutMs = useHexFlag("telemetry-timeout");
    const useStytchCNAME = useHexFlag("telemetry-use-stytch-cname");
    const isTelemetryEnabled = useIsTelemetryEnabled();
    const attribution = useAttribution();
    const googleCallback = useCallback(async () => {
      onSubmit();
      const url = Routes.GOOGLE_AUTH.getSignupUrl({
        attribution,
        params: await maybeIncludeTelemetry(
          authQueryParams,
          isTelemetryEnabled,
          telemetryTimeoutMs,
          useStytchCNAME,
        ),
      });
      window.location.href = url;
    }, [
      authQueryParams,
      isTelemetryEnabled,
      onSubmit,
      telemetryTimeoutMs,
      attribution,
      useStytchCNAME,
    ]);

    return (
      <LoginOption
        disabled={disabled}
        icon={<GoogleIcon />}
        onClick={googleCallback}
      >
        {children ?? (
          <>
            Sign up with <span>Google</span>
          </>
        )}
      </LoginOption>
    );
  },
);
