import { IntrinsicGroupId } from "@hex/common";
import React, { ReactNode } from "react";

import { GlobeIcon, WorkspaceIcon } from "../icons/CustomIcons";

import { GroupAvatar } from "./GroupAvatar";

export interface IntrinsicGroupAvatarProps {
  tooltip?: boolean;
  name: string;
  id: IntrinsicGroupId;
  userCount?: number;
}

export const IntrinsicGroupAvatar: React.ComponentType<IntrinsicGroupAvatarProps> =
  React.memo(function IntrinsicGroupAvatar({
    id,
    ...otherProps
  }: IntrinsicGroupAvatarProps) {
    let icon: ReactNode | undefined = undefined;

    if (id === IntrinsicGroupId.PUBLIC) {
      icon = <GlobeIcon />;
    } else if (id === IntrinsicGroupId.MEMBERS) {
      icon = <WorkspaceIcon />;
    }

    return <GroupAvatar icon={icon} {...otherProps} />;
  });
