import { Classes, Intent } from "@blueprintjs/core";
import { WorkspaceSetupSteps } from "@hex/common";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components";

import { HexButton, HexNonIdealState } from "../../hex-components";
import { HexToastProps } from "../common/Toasts";
import { Heading } from "../Heading";
import { WarningIcon } from "../icons/CustomIcons";

export const SetupStepInnerContainer = styled.div<{
  $fullScreen?: boolean;
  $wideScreen?: boolean;
}>`
  position: absolute;

  /* using this to position the container vertically -> can't use flex layout since this is absolutely positioned */
  top: ${({ $fullScreen }) => ($fullScreen ? `5%` : `15%`)};

  /* parent container expects that this has width of 500px, so need to negatively position for this to be centered */
  left: ${({ $wideScreen }) => ($wideScreen ? `-200px` : 0)};

  display: flex;
  flex-direction: column;
  gap: 40px;
  width: ${({ $wideScreen }) => ($wideScreen ? `900px` : `500px`)};

  :after {
    position: absolute;
    bottom: -30px;

    width: 500px;
    height: 30px;

    content: "";
  }
`;

export const SetupHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
export const SetupTitle = styled(Heading).attrs({ renderAs: "h1" })`
  margin: 0;
`;

export const SetupDescription = styled.div`
  margin: 0;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.HEADING_THREE};
`;

export const SetupMainTitle = styled(SetupTitle)`
  font-weight: ${({ theme }) => theme.fontWeight.BOLD};
  font-size: ${({ theme }) => theme.fontSize.DISPLAY_HEADING_ONE};
  letter-spacing: -2px;
  text-align: center;
`;

export const SetupMainTitleDescription = styled(SetupDescription)`
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  font-size: ${({ theme }) => theme.fontSize.HEADING_ONE};
  letter-spacing: -1.5px;
  text-align: center;
`;

export const SetupSmallDescription = styled(SetupDescription)`
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  font-size: ${({ theme }) => theme.fontSize.DEFAULT};
`;

export const SetupText = styled.p`
  margin: 0;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  font-size: ${({ theme }) => theme.fontSize.DEFAULT};
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 40px;
`;

export const WorkspaceSetupButton = styled(HexButton)`
  &&& {
    .${Classes.ICON} {
      opacity: 0.6;

      svg {
        color: ${({ theme }) => theme.marketingColors.OPAL};
      }
    }
  }
`;

export const WorkspaceSetupSubmitWrapper: React.ComponentType = React.memo(
  function WorkspaceSetupSubmitWrapper({ children }) {
    return <SubmitButtonWrapper>{children}</SubmitButtonWrapper>;
  },
);
export interface WorkspaceSetupStepProps {
  redirectToNextStep: (steps: WorkspaceSetupSteps | undefined) => void;
}

export const ActionDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const BackgroundDiv = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.backgroundColor.MUTED};
  background-image: linear-gradient(
    to left,
    transparent 125px,
    ${({ theme }) => rgba(theme.highlightColor, 0.12)} 125px,
    transparent 126px
  );
  background-position: center;

  background-size: 250px 250px;
`;

export const WorkspaceSetupNonIdealState: React.ComponentType = React.memo(
  function WorkspaceSetupNonIdealState() {
    return (
      <HexNonIdealState
        description="There was an error setting up your workspace. Refresh to try again, or contact support@hex.tech if the issue persists."
        icon={<WarningIcon />}
        title="Unknown error"
      />
    );
  },
);

export const WorkspaceSetupUnknownErrorToast: HexToastProps = {
  message: "Something went wrong",
  intent: Intent.DANGER,
  icon: <WarningIcon />,
  timeout: 1000,
};
