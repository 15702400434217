import { Intent } from "@blueprintjs/core";
import { rgba } from "polished";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { HexButton, HexCallout } from "../../hex-components";
import { Routes } from "../../route/routes";

import { Dropzone } from "./Dropzone";
import { FileUpload } from "./FileUpload";

const Container = styled.div<{ $small?: boolean }>`
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ $small }) => ($small ? "0px" : "20px")};
`;

const DropzoneWrapper = styled.div<{ $small?: boolean }>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ $small }) => ($small ? "70px" : "300px")};
`;

const StyledHexCallout = styled(HexCallout)`
  max-height: 200px;
  margin-top: 15px;
  overflow: auto;
`;

const ExistingFileButtons = styled.div`
  position: absolute;
  bottom: 10px;

  display: flex;

  gap: 5px;
  justify-content: center;
  width: 100%;

  visibility: hidden;
`;

export const ExistingFile = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => rgba(theme.backgroundColor.DEFAULT, 0.5)};
  border: 1px ${({ theme }) => theme.borderColor.DEFAULT};
  border-radius: ${({ theme }) => theme.borderRadius};
  &:hover {
    ${ExistingFileButtons} {
      visibility: visible;
    }
  }
`;

export const ExistingFileContents = styled.div<{ $fade?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  max-height: 100%;
  margin: 0;
  overflow: hidden;

  font-size: ${({ theme }) => theme.fontSize.EXTRA_SMALL};

  font-family: ${({ theme }) => theme.fontFamily.MONO};

  white-space: pre-line;
  mask-image: linear-gradient(180deg, #000 20%, transparent);

  ${({ $fade }) => ($fade ? `opacity: .45;` : "")}

  &:hover {
    ${ExistingFileButtons} {
      visibility: visible;
    }
  }
`;
export interface UploaderProps {
  onUpload: (file: File) => void;
  instructionText?: string;
  uploading?: boolean;
  error?: boolean;
  errorMessage?: string;
  clearFileSideEffect?: () => void;
  accept?: {
    regex: RegExp;
    human: string;
  };
  maxFileSize?: {
    value: number;
    human: string;
  };
  showMaxFileSize?: boolean;
  $small?: boolean;
  existingFileContents?: string;
  clearExistingFile?: () => void;
  downloadFileName?: string;
  disabled?: boolean;
}

export const Uploader: React.FunctionComponent<UploaderProps> = (props) => {
  const {
    $small = false,
    accept,
    clearExistingFile,
    clearFileSideEffect,
    disabled,
    downloadFileName,
    error,
    errorMessage,
    existingFileContents,
    instructionText,
    maxFileSize,
    onUpload,
    uploading = false,
  } = props;

  const [file, setFile] = useState<File | null>(null);
  const onDrop = useCallback((acceptedFiles: File[]): void => {
    setFile(acceptedFiles[0]);
  }, []);

  const onClearFile = useCallback(() => {
    if (clearFileSideEffect) {
      clearFileSideEffect();
    }
    setFile(null);
  }, [clearFileSideEffect]);

  const clearExistingFileCallback = useCallback(() => {
    clearExistingFile?.();
    onClearFile();
  }, [clearExistingFile, onClearFile]);

  const downloadFileContents = useCallback(() => {
    if (existingFileContents) {
      saveAs(
        new Blob([existingFileContents], {
          type: "text/plain",
        }),
        downloadFileName,
      );
    }
  }, [downloadFileName, existingFileContents]);

  const fileSizeError =
    file?.size != null && maxFileSize != null && file.size > maxFileSize.value;
  const fileTypeError =
    file?.name != null && accept != null && !accept.regex.test(file.name);

  return (
    <Container $small={$small}>
      <DropzoneWrapper $small={$small}>
        {file && !existingFileContents && (
          <FileUpload
            $small={$small}
            disabled={fileSizeError || fileTypeError || disabled}
            file={file}
            uploading={uploading}
            onClearFile={onClearFile}
            onUpload={onUpload}
          />
        )}
        {existingFileContents ? (
          <ExistingFile>
            <ExistingFileContents $fade={disabled}>
              {existingFileContents}
            </ExistingFileContents>
            {!disabled && (
              <>
                <ExistingFileButtons>
                  <HexButton
                    intent={Intent.DANGER}
                    small={$small}
                    onClick={clearExistingFileCallback}
                  >
                    Clear
                  </HexButton>
                  <HexButton
                    intent={Intent.NONE}
                    small={$small}
                    onClick={downloadFileContents}
                  >
                    Download
                  </HexButton>
                </ExistingFileButtons>
              </>
            )}
          </ExistingFile>
        ) : (
          <Dropzone
            $small={$small}
            disabled={disabled || uploading}
            hasChosenFile={file ? true : false}
            instructionText={instructionText}
            maxFileSize={$small ? undefined : maxFileSize?.human}
            onDrop={onDrop}
          />
        )}
      </DropzoneWrapper>

      {fileTypeError && (
        <StyledHexCallout intent={Intent.DANGER} title="Unsupported file type">
          {accept?.human}
        </StyledHexCallout>
      )}
      {fileSizeError && maxFileSize && (
        <StyledHexCallout intent={Intent.DANGER} title="File too large">
          The maximum file size is {maxFileSize.human}.
        </StyledHexCallout>
      )}
      {!fileSizeError && error && !errorMessage && (
        <StyledHexCallout
          $size={$small ? "small" : "default"}
          intent={Intent.DANGER}
          title="An error occurred while uploading your file"
        >
          Please try again or contact&nbsp;
          <a href={Routes.SUPPORT_EMAIL.getUrl()}>support@hex.tech</a>.
        </StyledHexCallout>
      )}
      {!fileSizeError && error && errorMessage && (
        <StyledHexCallout
          $size={$small ? "small" : "default"}
          intent={Intent.DANGER}
          title="An error occurred while uploading your file"
        >
          <pre>{errorMessage}</pre>
        </StyledHexCallout>
      )}
    </Container>
  );
};
