import { gql } from "@apollo/client";
import { Classes } from "@blueprintjs/core";
import {
  CollectionId,
  KernelImage,
  KernelSize,
  ProjectLanguage,
  ProjectRole,
  SpecialVersionType,
} from "@hex/common";
import { groupBy } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  HexButton,
  HexCallout,
  HexDialog,
  HexTooltip,
} from "../../hex-components";
import { HexClasses } from "../../hex-components/classes.js";
import { useGlobalHotKey } from "../../hooks/useGlobalHotKey";
import {
  MinimalCategory,
  MinimalStatus,
} from "../../hooks/useProjectLabelsForHex";
import { useToggleState } from "../../hooks/useToggleState";
import { Routes } from "../../route/routes";
import { CyData } from "../../util/cypress";
import { useDialog } from "../../util/dialogs";
import { HotKeys } from "../../util/hotkeys";
import { Keys, evtModKey } from "../../util/Keys";
import { useEnforceProjectLimit } from "../../util/useEnforceProjectLimit";
import { useHexFlag } from "../../util/useHexFlags";
import {
  DESCRIPTION_MAX_LENGTH,
  DescriptionLengthLimit,
  DescriptionTextArea,
  DescriptionWrapper,
} from "../app/ProjectDescription";
import { ProjectLabels } from "../app/ProjectLabels";
import { ControlledContentEditable } from "../common/ControlledContentEditable";
import { KernelImagePicker } from "../compute/KernelImagePicker";
import { KernelSizePicker } from "../compute/KernelSizePicker";
import { ProjectLanguagePicker } from "../compute/ProjectLanguagePicker";
import { useFeatureGates } from "../feature-gate/FeatureGateContext";
import { GetCollectionForShowTab2Document } from "../home/collections-tab/collection-show/CollectionShowTab2.generated";
import { AddCollectionsToProjectSection } from "../home/collections-tab/dialogs/AddCollectionsToProjectSection";
import { CollectionWithRole } from "../home/collections-tab/dialogs/AddProjectToCollectionsDialog";
import { useAddCollectionsToHexMutation } from "../home/collections-tab/dialogs/AddProjectToCollectionsDialog.generated";
import { CollectionSharingSummary } from "../home/collections-tab/shared/CollectionSharingSummary";
import {
  SingleChevronDownIcon,
  SingleChevronUpIcon,
} from "../icons/CustomIcons";
import { EditableNameWrapper } from "../logic/EditableNameWrapper";
import {
  DEFAULT_PROJECT_TITLE,
  TitleContentEditable,
} from "../logic/ProjectMetadata";
import { RoleDropdown } from "../share/RoleDropdown";

import {
  useCollectionDetailsForProjectDialogQuery,
  useCreateHexMutation,
} from "./NewProjectDialog.generated";
import { useCreateNewDefaultProject } from "./useCreateNewDefaultProject";

gql`
  mutation CreateHex(
    $name: String!
    $projectLanguage: ProjectLanguage
    $description: String
    $categoryIds: [CategoryId!]
    $statusId: StatusId
    $kernelSize: KernelSize
    $kernelImage: KernelImage
    $getProjectCount: Boolean = false
    $collectionId: CollectionId
    $collectionProjectRole: ProjectRole
    $isTour: Boolean = false
  ) {
    createHex(
      name: $name
      projectLanguage: $projectLanguage
      description: $description
      categoryIds: $categoryIds
      statusId: $statusId
      kernelSize: $kernelSize
      kernelImage: $kernelImage
      collectionId: $collectionId
      collectionProjectRole: $collectionProjectRole
      hexType: PROJECT
      isTour: $isTour
    ) {
      id
      org {
        id
        projectCount @include(if: $getProjectCount)
      }
    }
  }
`;

gql`
  query CollectionDetailsForProjectDialog($collectionId: CollectionId!) {
    collectionById(collectionId: $collectionId) {
      id
      name
      emoji
      collectionGrants {
        id
        ...CollectionGrantFragment
      }
    }
  }
`;

const StyledDialog = styled(HexDialog)`
  && {
    width: 520px;

    padding: 0;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
`;

const TitleDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const Metadata = styled.div`
  display: flex;
`;

const CollectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding: 12px 12px 8px 12px;

  border: 1px solid ${({ theme }) => theme.borderColor.DEFAULT};

  border-radius: ${({ theme }) => theme.borderRadius};
`;

const CollectionPermissionWrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

const CollectionPermissionText = styled.div`
  line-height: 24px;
`;

const CollectionPermissionSummary = styled(HexTooltip)`
  text-decoration: underline dotted;
`;

const CollectionMetadata = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const AddCollectionsSearch = styled.div`
  display: flex;
  width: 100%;
`;

const CollectionName = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  font-size: ${({ theme }) => theme.fontSize.LARGE};
`;

const CollectionRole = styled.div`
  flex-shrink: 0;

  &&&&& .${Classes.BUTTON_TEXT} {
    color: ${({ theme }) => theme.fontColor.DEFAULT};
  }
`;

const Footer = styled.div`
  display: flex;
  padding: 0 10px 0 20px;

  border-top: 1px solid ${({ theme }) => theme.borderColor.MUTED};
`;

const AdvancedFooterGroup = styled.div`
  height: 100%;
  margin-left: -4px;
  overflow: hidden;
`;

const AdvancedToggleButton = styled(HexButton)`
  .${Classes.BUTTON_TEXT}:not(:last-child) {
    margin-right: 3px;
  }
`;

const AdvancedInner = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  height: calc(100% * 2);

  transition: transform calc(${({ theme }) => theme.animation.duration} * 2)
    ${({ theme }) => theme.animation.easing};

  ${({ $isOpen }) =>
    $isOpen ? "transform: translate(0, -50%);" : "transform: translate(0, 0);"}
`;

const AdvancedSection = styled.div<{ $faded: boolean }>`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  height: 50%;

  transition: all ${({ theme }) => theme.animation.duration}
    ${({ theme }) => theme.animation.easing};

  ${({ $faded }) => ($faded ? "opacity: 0;" : "opacity: 1;")}
`;

const Subheading = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

const StyledHexButton = styled(HexButton)`
  margin-left: -5px;
  padding: 0px;
`;

const LeftActions = styled.div`
  display: flex;
  flex: initial;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
`;

const RightActions = styled.div`
  display: flex;
  flex: none;
  align-items: center;
  margin-left: auto;
  padding: 10px 0;

  .${HexClasses.HEX_BUTTON} {
    flex: none;
  }
`;

export const NewProjectDialog: React.FunctionComponent = () => {
  const history = useHistory();
  const {
    closeDialog: closeDialog_,
    isOpen,
    openDialog,
  } = useDialog("new-project");
  const additionalBaseImages = useHexFlag("additional-base-images");
  const explorerRoleCanViewChange = useHexFlag("explorer-role-can-view-change");

  const { collectionId: collectionIdParam } = useParams<{
    collectionId: string;
  }>();
  const collectionId = collectionIdParam
    ? (collectionIdParam as CollectionId)
    : null;

  const { configurableKernelSize, projectLimit } = useFeatureGates();
  const [createHex] = useCreateHexMutation({
    refetchQueries: () =>
      collectionId
        ? [
            {
              query: GetCollectionForShowTab2Document,
              variables: { collectionId },
            },
          ]
        : [],
  });

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const [currentTitle, setCurrentTitle] = useState<string>(
    DEFAULT_PROJECT_TITLE,
  );
  const [editingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [creationError, setCreationError] = useState<boolean>(false);
  const [currentDescription, setCurrentDescription] = useState<string>("");

  const [collections, setCollections] = useState<CollectionWithRole[]>([]);
  const [isCollectionsPendingSearch, setIsCollectionsPendingSearch] =
    useState<boolean>(false);

  const [
    isCollectionSearchExpanded,
    ,
    { setFalse: closeCollectionsSearch, toggle: openCollectionsSearch },
  ] = useToggleState(false);

  const { projectLimitEnforced } = useEnforceProjectLimit();

  const nameRef = useRef<ControlledContentEditable>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  const [appliedCategories, setAppliedCategories] = useState<
    readonly MinimalCategory[]
  >([]);
  const [appliedStatus, setAppliedStatus] = useState<MinimalStatus | null>(
    null,
  );
  const [appliedLanguage, setAppliedLanguage] = useState<ProjectLanguage>(
    ProjectLanguage.PYTHON,
  );
  const [advancedOpen, , { setFalse: closeAdvanced, toggle: toggleAdvanced }] =
    useToggleState(false);
  const [kernelSize, setKernelSize] = useState<KernelSize | null>(null);
  const [kernelImage, setKernelImage] = useState<KernelImage | null>(null);

  const clearState = useCallback(() => {
    setIsCreating(false);
    setCreationError(false);
    closeAdvanced();
    setCollections([]);
    closeCollectionsSearch();
  }, [closeAdvanced, closeCollectionsSearch]);
  const closeDialog = useCallback(() => {
    clearState();
    closeDialog_();
  }, [clearState, closeDialog_]);

  const toggleDialog = useCallback(() => {
    if (isOpen) {
      closeDialog();
    } else {
      openDialog();
      setIsEditingTitle(true);
    }
  }, [closeDialog, isOpen, openDialog]);

  // Select name on mount
  useEffect(() => {
    setIsEditingTitle(true);
  }, [isOpen]);

  // This allows the textarea to grow with the content
  useEffect(() => {
    if (descriptionRef.current != null) {
      descriptionRef.current.style.height = "0px";
      const scrollHeight = descriptionRef.current.scrollHeight;
      descriptionRef.current.style.height = scrollHeight + "px";
    }
  }, [currentDescription]);

  const descriptionTextAreaCallback = useCallback((evt) => {
    setCurrentDescription(evt.target.value);
  }, []);

  const saveTitle = useCallback((): void => {
    setCurrentTitle(currentTitle || DEFAULT_PROJECT_TITLE);
    setIsEditingTitle(false);
  }, [currentTitle]);

  const cancelName = useCallback((): void => {
    saveTitle();
    closeDialog();
  }, [closeDialog, saveTitle]);

  const selectStatus = useCallback((newStatus: MinimalStatus | null): void => {
    setAppliedStatus(newStatus);
  }, []);

  const selectCategory = useCallback(
    (category: MinimalCategory, applied: boolean): void => {
      if (!applied) {
        const newCategories = [...appliedCategories, category];
        setAppliedCategories(newCategories);
      } else {
        const newCategories = appliedCategories.filter(
          (item) => item.id !== category.id,
        );
        setAppliedCategories(newCategories);
      }
    },
    [appliedCategories],
  );

  const [collectionProjectRole, setCollectionProjectRole] =
    useState<ProjectRole | null>(ProjectRole.VIEWER);
  const { data: collectionData } = useCollectionDetailsForProjectDialogQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { collectionId: collectionId! },
    skip: collectionId == null,
  });
  const collection = collectionData?.collectionById;
  const grants = collection?.collectionGrants;

  const selectLanguage = useCallback((language: ProjectLanguage): void => {
    setAppliedLanguage(language);
  }, []);

  const [addCollectionsMutation] = useAddCollectionsToHexMutation();

  const createHexCallback = useCallback(async () => {
    setIsCreating(true);

    const appliedCategoryIds = appliedCategories.map((o) => o.id);

    try {
      const { data } = await createHex({
        variables: {
          name: currentTitle,
          projectLanguage: appliedLanguage,
          description: currentDescription,
          statusId: appliedStatus?.id ?? null,
          categoryIds: appliedCategoryIds,
          kernelImage,
          kernelSize,
          getProjectCount: projectLimit != null,
          collectionId: collectionId,
          collectionProjectRole: collectionId ? collectionProjectRole : null,
        },
      });
      if (data?.createHex?.id == null) {
        throw new Error("Creation failed");
      }

      // todo(GRVTY-1093): refactor the createHex mutation so that we can create Hex and add in all collections in one call.
      if (collections.length > 0) {
        const groupedLinks = groupBy(collections, "role");
        await addCollectionsMutation({
          variables: {
            hexId: data.createHex.id,
            appUserCollectionIds:
              groupedLinks[ProjectRole.APP_USER]?.map((p) => p.id) ?? [],
            viewerCollectionIds:
              groupedLinks[ProjectRole.VIEWER]?.map((p) => p.id) ?? [],
            editorCollectionIds:
              groupedLinks[ProjectRole.EDITOR]?.map((p) => p.id) ?? [],
            ownerCollectionIds:
              groupedLinks[ProjectRole.OWNER]?.map((p) => p.id) ?? [],
            noAddedGrantCollectionIds:
              groupedLinks["null"]?.map((p) => p.id) ?? [],
          },
        });
      }

      clearState();
      Routes.push(history, Routes.LOGIC, {
        hexId: data.createHex.id,
        version: SpecialVersionType.DRAFT,
      });
    } catch (e) {
      console.error(e);
      setCreationError(true);
      setIsCreating(false);
    }
  }, [
    appliedCategories,
    createHex,
    currentTitle,
    appliedLanguage,
    currentDescription,
    appliedStatus?.id,
    kernelImage,
    kernelSize,
    projectLimit,
    collectionId,
    collectionProjectRole,
    collections,
    clearState,
    history,
    addCollectionsMutation,
  ]);

  const modEnterKeyPress = useCallback(
    (evt) => {
      // shift-enter
      if (evt.key === Keys.ENTER && evtModKey(evt)) {
        evt.preventDefault();
        void createHexCallback();
      }
    },
    [createHexCallback],
  );

  const justEnterKeyPress = useCallback(
    (evt) => {
      // enter
      if (evt.key === Keys.ENTER) {
        evt.preventDefault();
        void createHexCallback();
      }
    },
    [createHexCallback],
  );

  const { createPythonProject } = useCreateNewDefaultProject();

  // New project hot key
  useGlobalHotKey(
    async (evt) => {
      if (!isOpen && !projectLimitEnforced) {
        evt.preventDefault();
        await createPythonProject();
        return true;
      }
      return false;
    },
    [createPythonProject, isOpen, projectLimitEnforced],
    { keys: HotKeys.NEW_PROJECT, name: "New project" },
  );

  // Submit form hot key
  useGlobalHotKey(
    (evt) => {
      if (isOpen) {
        evt.preventDefault();
        void createHexCallback();
        return true;
      }
      return false;
    },
    [createHexCallback, isOpen],
    { keys: HotKeys.CREATE_PROJECT, name: "Create project (submit)" },
  );

  const noDisabledCollectionIds: Set<CollectionId> = new Set();

  const showExistingCollectionLinkSection = (
    <CollectionContainer>
      <CollectionMetadata>
        <CollectionName>
          {collection?.emoji && <div>{collection?.emoji}</div>}
          <div>{collection?.name}</div>
        </CollectionName>
      </CollectionMetadata>
      <CollectionPermissionWrapper>
        <CollectionPermissionText>
          <CollectionPermissionSummary
            content={
              <CollectionSharingSummary
                collectionGrants={grants}
                noTooltip={true}
              />
            }
            position="bottom"
          >
            Collection managers & members
          </CollectionPermissionSummary>{" "}
          will get additional access:
        </CollectionPermissionText>
        <CollectionRole>
          <RoleDropdown
            explorerRoleCanViewChange={explorerRoleCanViewChange}
            isComponent={false}
            isInCollectionsContext={true}
            maxRole={ProjectRole.OWNER}
            minRole={ProjectRole.APP_USER}
            selectedRole={collectionProjectRole}
            small={true}
            onSelectRole={setCollectionProjectRole}
          />
        </CollectionRole>
      </CollectionPermissionWrapper>
    </CollectionContainer>
  );

  const addProjectToCollectionsSection = (
    <>
      {isCollectionSearchExpanded ? (
        <AdvancedSection $faded={!isCollectionSearchExpanded}>
          <CollectionContainer>
            <Subheading>
              This project will be added to the following collection(s):
            </Subheading>
            <AddCollectionsSearch>
              <AddCollectionsToProjectSection
                canShare={true}
                collections={collections}
                disabledCollectionIds={noDisabledCollectionIds}
                isComponent={false}
                maxGrantableRole={ProjectRole.OWNER}
                setActiveTagsInSearch={setIsCollectionsPendingSearch}
                setCollections={setCollections}
              />
            </AddCollectionsSearch>
          </CollectionContainer>
        </AdvancedSection>
      ) : (
        <AdvancedSection $faded={isCollectionSearchExpanded}>
          <StyledHexButton
            minimal={true}
            rightIcon={<SingleChevronDownIcon iconSize={14} />}
            small={true}
            text="Add to collections"
            onClick={openCollectionsSearch}
          />
        </AdvancedSection>
      )}
    </>
  );

  return (
    <StyledDialog
      isCloseButtonShown={true}
      isOpen={isOpen}
      title="New project"
      usePortal={true}
      onClose={toggleDialog}
    >
      <Body>
        <TitleDescription>
          <EditableNameWrapper>
            <TitleContentEditable
              ref={nameRef}
              $editable={true}
              content={currentTitle}
              isEditing={editingTitle}
              selectAllWhenEditingStarts={true}
              onBlur={saveTitle}
              onCancel={cancelName}
              onChange={setCurrentTitle}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => setIsEditingTitle(true)}
              onKeyPressDeprecated={justEnterKeyPress}
              onSave={saveTitle}
            />
          </EditableNameWrapper>
          <DescriptionWrapper>
            <DescriptionTextArea
              ref={descriptionRef}
              $editable={true}
              maxLength={DESCRIPTION_MAX_LENGTH}
              placeholder="Add description..."
              rows={1}
              value={currentDescription}
              onChange={descriptionTextAreaCallback}
              onKeyDown={modEnterKeyPress}
            />
            <DescriptionLengthLimit
              count={currentDescription.length}
              max={DESCRIPTION_MAX_LENGTH}
            />
          </DescriptionWrapper>
        </TitleDescription>
        <Metadata>
          <ProjectLabels
            appliedCategories={appliedCategories}
            appliedStatus={appliedStatus}
            resourceType="hex"
            onSelectCategory={selectCategory}
            onSelectStatus={selectStatus}
          />
          <ProjectLanguagePicker
            appliedLanguage={appliedLanguage}
            onSelectLanguage={selectLanguage}
          />
        </Metadata>
        {collection
          ? showExistingCollectionLinkSection
          : addProjectToCollectionsSection}
        {creationError && (
          <HexCallout
            $size="small"
            css={`
              width: 100%;
            `}
            icon={undefined}
            intent="danger"
          >
            Creation failed. Contact support if issue persists.
          </HexCallout>
        )}
      </Body>
      <Footer>
        <LeftActions>
          {(additionalBaseImages || configurableKernelSize) && (
            <AdvancedFooterGroup>
              <AdvancedInner $isOpen={advancedOpen}>
                <AdvancedSection $faded={advancedOpen}>
                  <AdvancedToggleButton
                    minimal={true}
                    rightIcon={<SingleChevronUpIcon iconSize={14} />}
                    small={true}
                    text="Advanced options"
                    onClick={toggleAdvanced}
                  />
                </AdvancedSection>
                <AdvancedSection $faded={!advancedOpen}>
                  {additionalBaseImages && (
                    <KernelImagePicker
                      currentKernelImage={kernelImage}
                      projectLanguage={appliedLanguage}
                      onSelect={setKernelImage}
                    />
                  )}
                  {configurableKernelSize && (
                    <KernelSizePicker
                      currentKernelSize={kernelSize}
                      onSelect={setKernelSize}
                    />
                  )}
                </AdvancedSection>
              </AdvancedInner>
            </AdvancedFooterGroup>
          )}
        </LeftActions>
        <RightActions>
          <HexTooltip
            content="There are pending Collections in the search bar. Please add or remove."
            disabled={!isCollectionsPendingSearch}
            interactionKind="hover"
            position="top"
          >
            <HexButton
              data-cy={CyData.CREATE_PROJECT}
              disabled={isCollectionsPendingSearch}
              intent="success"
              loading={isCreating}
              text="Create project"
              onClick={createHexCallback}
            />
          </HexTooltip>
        </RightActions>
      </Footer>
    </StyledDialog>
  );
};
